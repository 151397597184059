<style lang="scss"></style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="headline-h1">
          <h1>User</h1>
          <div class="line"><span>Passwort ändern</span></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-6">
        <div class="form-group"><span class="label-input">Passwort</span> <input type="text" v-model="pw1" /></div>
        <div class="form-group"><span class="label-input">Passwort wiederholen</span> <input type="text" v-model="pw2" /></div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-4">
        <button @click="change" class="btn-std btn-color-save">Speichern</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const pw1 = ref();
    const pw2 = ref();

    const change = () => {
      let error = false;
      if (!pw1.value || pw1.value == '') {
        error = true;
        store.dispatch('addNotification', { type: 'error', message: 'Bitte geben Sie ein Passwort ein.' });
      }

      if (pw1.value != pw2.value) {
        error = true;
        store.dispatch('addNotification', { type: 'error', message: 'Die Passwörter stimmen nicht überein.' });
      }

      if (!error) {
        store.dispatch('changePassword', pw1.value).then(() => {
          store.dispatch('addNotification', { message: 'Ihr Passwort wurde geändert.' });
        });
      }
    };

    return {
      change,
      pw1,
      pw2,
    };
  },
});
</script>
