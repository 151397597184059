
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const pw1 = ref();
    const pw2 = ref();

    const change = () => {
      let error = false;
      if (!pw1.value || pw1.value == '') {
        error = true;
        store.dispatch('addNotification', { type: 'error', message: 'Bitte geben Sie ein Passwort ein.' });
      }

      if (pw1.value != pw2.value) {
        error = true;
        store.dispatch('addNotification', { type: 'error', message: 'Die Passwörter stimmen nicht überein.' });
      }

      if (!error) {
        store.dispatch('changePassword', pw1.value).then(() => {
          store.dispatch('addNotification', { message: 'Ihr Passwort wurde geändert.' });
        });
      }
    };

    return {
      change,
      pw1,
      pw2,
    };
  },
});
